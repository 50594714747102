import {MessageStore} from "@/views/Message/Store";
import Socket from "@/Api/Socket";
import {IndexStore} from "@/store/modules/Index";

/**
 * socket 链接
 * */
export function socketLink() {
    // let phone = navigator.userAgent;
    // let wxBool = phone.toLowerCase().match(/MicroMessenger/i)
    // if ( !( wxBool != null && wxBool[0] == "micromessenger" ) ){
    //
    // }
    try{
        try{
            MessageStore.getMessageSocket.close()
        }catch (e) {
            console.info("初始化-关闭失败")
        }
        MessageStore.SetMessageSocket(new Socket());
    }catch (e) {
        console.info("初始化socket-App失败",process.env.NODE_ENV === "development" ? "ws://test.campus.31xiaoyuan.com:8006/chat/" : 'wss://campus.31xiaoyuan.com:8006/chat/',e)
    }
}

/**
 * socket 关闭
 * */
export function socketClose() {
    let phone = navigator.userAgent;
    let wxBool = phone.toLowerCase().match(/MicroMessenger/i)
    if ( !( wxBool != null && wxBool[0] == "micromessenger" ) ){
        try{
            MessageStore.getMessageSocket.close()
        }catch (e) {
            console.info("初始化-关闭失败")
        }
    }
}

/**
 * 网络 波动 监听
 * */
export function networkState() {
    window.addEventListener("online",(...props)=>{
        console.log("网络已连接",props)
        if ( !IndexStore.GetLoading ){
            try{
                MessageStore.getMessageSocket.close()
            }catch (e) {
                console.info("初始化-关闭失败")
            }
            return
        }
        socketLink();
    },false);
    window.addEventListener("offline",(...props)=>{
        console.log("网络断开",props)
        socketClose()
    },false);
}

/**
 * 监听 App 返回
 * */
export function backApp() {
    window.addEventListener("popstate",()=>{
        console.log("执行监听返回 按钮")
    },false)
}
