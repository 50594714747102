import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible"

import Loading from "@/components/ShareComponent/loading.vue"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import PullSelItem from "@/components/ShareComponent/PullSelItem.vue"
import ScrollView from "@/components/ShareComponent/ScrollView.vue";
import ScrollViewX from "@/components/ShareComponent/ScrollViewX.vue";
import SearchCom from "@/components/ShareComponent/Search.vue";
import ChangeInput from "@/components/ShareComponent/InputVal/index.vue";

import {
    Lazyload,
    Tabbar,
    TabbarItem,
    Swipe,
    SwipeItem,
    Image as VanImage,
    Popup,
    NumberKeyboard,
    Picker,
    Area,
    Uploader,
    Field,
    Button,
    Loading as loading,
    List,
    IndexBar,
    IndexAnchor,
    NoticeBar,
    SwipeCell,
    PullRefresh,
    Tag,
    Tab,
    Tabs,
    Calendar,
    Divider,
    Dialog,
} from "vant"

// ui 组件挂载
Vue.use(Lazyload, {
    lazyComponent: true,
}).use(Tabbar).use(TabbarItem).use(Swipe).use(SwipeItem).use(VanImage)
    .use(Popup).use(NumberKeyboard).use(Picker).use(Area).use(Uploader).use(Field).use(Button)
    .use(loading).use(List).use(IndexBar).use(IndexAnchor).use(NoticeBar).use(SwipeCell).use(PullRefresh).use(Tag)
    .use(Tab).use(Tabs).use(Calendar).use(Divider).use(Dialog);

/** 自定义 组件 全局注册 */
Vue.component(Loading.name, Loading)
Vue.component(HeadTop.name, HeadTop)
Vue.component(PullDownUpList.name, PullDownUpList)
Vue.component(ListBottom.name, ListBottom)
Vue.component(PullSelItem.name, PullSelItem)
Vue.component(ScrollView.name, ScrollView)
Vue.component(ScrollViewX.name, ScrollViewX)
Vue.component(SearchCom.name, SearchCom)
Vue.component(ChangeInput.name,ChangeInput)

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App as any)
}).$mount("#app");

if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker.register("/service-worker.js").catch((cat: any) => cat)
    }, false)
}

