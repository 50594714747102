
declare const ap:any

export function initLocation() {
    let phone = navigator.userAgent;
    let isiOS = !!phone.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let isAndroid = phone.indexOf('Android') > -1 || phone.indexOf('Linux') > -1;
    let wxBool = phone.toLowerCase().match(/MicroMessenger/i)
    let zfbBool = phone.toLowerCase().match(/AlipayClient/i)

    if ( wxBool != null && wxBool[0] == "micromessenger" ){
        let srcArr = [
            {
                url:"https://res.wx.qq.com/open/js/jweixin-1.6.0.js"
            },{
                url:"https://res2.wx.qq.com/open/js/jweixin-1.6.0.js"
            },
        ]
        srcArr.forEach((item,index)=>{
            let script=document.createElement("script")
            script.src = item.url
            document.body.appendChild(script)
        })
    }else{
        // let cssArr = [
        //     {
        //         href:"https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css",
        //         rel:"stylesheet"
        //     }
        // ]
        // cssArr.forEach((item,index)=>{
        //     let link=document.createElement("link")
        //     link.rel = item.rel
        //     link.href = item.href
        //     document.head.appendChild(link)
        // })
        let srcArr = [
            {
                url:"https://webapi.amap.com/maps?v=1.4.15&key=b1e68d6653f941493703afb64a7bb981",
                type:"text/javascript"
            },
        ]
        srcArr.forEach((item,index)=>{
            let script=document.createElement("script")
            script.src = item.url
            script.type = item.type
            document.body.appendChild(script)
        })
    }

    // let script=document.createElement("script")
    // script.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js'
    // document.body.appendChild(script)
    // script.onload = function () {
    //     console.log("加载成功",ap);
    // }
}

declare const AMap:any

export function isMap() {
    return (
        new Promise((resolve, reject) => {
            let num = 0;
            let time = setInterval(()=>{
                if ( AMap && "Map" in AMap ){
                    clearInterval(time)
                    resolve(true)
                }else if ( num >= 1000 ){
                    clearInterval(time)
                    reject(false)
                }
                num ++;
            },300)
        })
    )
}


