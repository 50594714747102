












import { Vue,Component,Ref,Prop,Watch,Emit } from "vue-property-decorator"

@Component({ name:"ScrollViewX" })
export default class App extends Vue{
    Width = 0;
    BoxWidth = 0;
    MaxWidth = 0;
    PageX = 0;
    Cha = 0;
    Left = 0;
    OldLeft = 0;
    LeftBool = false // 是否向左 滑动
    MoveBool = false
    time:any = ""
    Num = 0;
    Animation = false; // 是否开启动画


    mounted() {
        this.GetSBoxHtml.addEventListener("touchstart",this.handleStart)
        this.GetSBoxHtml.addEventListener("touchmove",this.handleMove)
        this.GetSBoxHtml.addEventListener("touchend",this.handleEnd)
    }

    handleStart(e:any){
        this.Width = parseFloat( getComputedStyle(this.GetScrollXHtml).width )
        this.BoxWidth = this.GetSBoxHtml.offsetWidth
        this.MaxWidth = this.BoxWidth - this.Width
        let touch = e.changedTouches[0]
        this.PageX = touch.pageX
        this.OldLeft = this.Left
        this.MoveBool = false
        this.Animation = false
        this.Num = 0
        this.time = setInterval(()=>{
           this.Num ++;
        })
    }

    handleMove(e:any){
        if( this.MaxWidth < 0 )return;
        let touch = e.changedTouches[0]
        let PageX = ( ( touch.pageX - this.PageX )  ) + this.OldLeft
        if( PageX >= this.MaxWidth ){
            this.Left = 0
        } else if( PageX < 0 && Math.abs( PageX ) >= this.MaxWidth ){
            this.Left = -this.MaxWidth
        }else{
            if( PageX >= 0 ){
                this.Left = 0
            }else{
                this.Left = PageX
            }
        }
        if( touch.pageX >= this.PageX ){
            this.LeftBool = true
        }else{
            this.LeftBool = false
        }

        if( this.GetScrollBool ){
            this.PullData()
        }
        this.MoveBool = true
    }

    handleEnd(e:any){
        if( this.MaxWidth < 0 )return;
        if( !this.MoveBool )return;
        if( this.Num <= 40 ){
            this.Animation = true
            let Num = 0
            if( this.LeftBool ){
                Num = this.Left + ( Math.abs( this.Left )  )
            }else{
                Num = this.Left * 2
            }
            if( Math.abs(Num) >= this.MaxWidth ){
                this.Left = -this.MaxWidth
            }else{
                if( Num >= 0 ){
                   this.Left = 0
                }else{
                   this.Left = Num
                }
            }
            clearInterval( this.time )
        }

        if( this.GetScrollBool ){
            this.PullData()
        }
    }

    @Ref("scrollX")
    scrollXHtml!:HTMLDListElement;
    get GetScrollXHtml(){
        return this.scrollXHtml
    }

    @Ref("scrollBoxX")
    scrollBoxXHtml!:HTMLDivElement;
    get GetSBoxHtml(){
        return this.scrollBoxXHtml
    }

    @Prop(Number)
    scrollX!:number
    get GetScrollX(){
        return this.scrollX
    }
    @Watch("GetScrollX")
    handleChangeScroll( newVal:number ){
        this.Animation = true
        this.Left = newVal
    }

    @Prop(Boolean)
    scrollBool!:boolean
    get GetScrollBool(){
        return this.scrollBool
    }

    @Emit("PullData")
    PullData(){
        return this.Left
    }
}
