




























import { Vue,Component,Prop,Watch,Emit, } from "vue-property-decorator"

@Component({ name:"PullSelItem" })
export default class PullSelItem extends Vue{
    RightIcon = require("$icon/Basics/Right.png");
    SelWidthBool = false
    SelList = [
        { title:"这是内容" },{ title:"这是内容1" },{ title:"这是内容2" },{ title:"这是内容3" },
    ]
    SelShow = false
    Index = 0
    ActiveColorBool = false;
    ColorBool = false
    titleShow = false

   // 标题展示的高度
   @Prop([Number,String])
   height!:string|number;

   get GetHeight():string|number{
       if( typeof this.height === "string" ){
           return this.height
       }else if( typeof this.height === "number" ){
            return this.height + "px"
       }else{
            return this.height
       }
   }
   // 下拉宽度
   @Prop(String)
   SelWidth!:string

   get GetSelWidth():string{
       if( this.SelWidth ){
           this.SelWidthBool = true
           return this.SelWidth
       }else{
           this.SelWidthBool = false
           return ""
       }
   }
   // 选中的第几个
   @Prop([Number,String])
   value!:number

   get GetValue(){
       return this.value
   }

   @Watch("GetValue")
   handleChangeValue(newVal:number){
       this.Index = newVal
   }

   // 传入数据
   @Prop(Array)
   list!:Array<{title:string,[propsData:string]:any}>

   get GetList(){
       return this.list
   }

   @Watch("GetList")
   handleChangeList(newVal:any){
       this.SelList = newVal
   }

   // 选中的 文字字体颜色
   @Prop(String)
   activeColor!:string;

   get GetActiveColor():string{
       if( this.activeColor ){
           this.ActiveColorBool = true
           return this.activeColor
       }else{
           this.ActiveColorBool = false
           return ""
       }
   }

   @Prop(String)
   color!:string;

   get GetColor():string{
       if( this.color ){
           this.ColorBool = true
           return this.color
       }else{
           this.ColorBool = false
           return ""
       }
   }

   // 是否显示
   @Prop(Boolean)
    show!:boolean

    get GetShow(){
        return this.show
    }

    @Watch("GetShow")
    handleShow(newVal:boolean){
        this.SelShow = newVal
    }

    // 索引
    @Prop([String,Number])
    state!:string|number;

    get GetState():string|number{
        return this.state
    }

    // 标题 内容
    @Prop(String)
    title!:string
    get GetTitle(){
        if ( this.title ) {
            this.titleShow = true
        }else{
            this.titleShow = false
        }
        return this.title
    }
    @Watch("GetTitle")
    handleChangeGetTitle(newVal:any){
        if ( newVal ) {
            this.titleShow = true
        }else{
            this.titleShow = false
        }
    }

    // 是否禁止选择下拉
    @Prop(Boolean)
    StopBool!:boolean;

    get GetStopBool():boolean{
        if ( this.StopBool ) {
            return true
        }else{
            return false
        }
    }

   handleChangeToagge(){
       if ( this.GetStopBool ) return;
       this.SelShow = !this.SelShow
       if ( this.SelShow ) {
           document.body.style.overflow = "hidden"
       }else{
           document.body.style.overflow = "auto"
       }
   }

   handleChangeItem(data:any,index:number,e:any){
       if( this.Index != index ){
           this.Index = index
           this.handleChangeToagge()
           this.PullActive()
           e.stopPropagation();
           e.preventDefault();
       }
   }

   @Emit("PullActive")
   PullActive(){
       return {
           index:this.Index,
           data:this.SelList[this.Index],
           state:this.GetState || 0
       }
   }
}
