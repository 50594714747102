















import { Vue,Component,Prop,Watch } from "vue-property-decorator"

@Component({ name:"ListButton" })
export default class ListButton extends Vue{
   loading = true;

   @Prop(Boolean)
   loadingShow!:boolean;

   get GetLoadingShow():boolean{
       this.loading = this.loadingShow
       return this.loadingShow
   }

   @Watch("GetLoadingShow")
   handleChangeLoading(newval:any){
       this.loading = newval
   }

   @Prop(String)
   loadingText!:string

   get GetLoadingText():string{
       if( this.loadingText ){
           return this.loadingText
       }else{
           return "亲只能加载这么多了~"
       }
   }

   @Prop(String)
   background!:string

   get GetBackground():string{
       if( this.background ){
       return this.background as string
       }else{
       return "#f2f2f2"
       }
   }
}
