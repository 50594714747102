

export default [
    {
        path:"/addressList",
        name:"addressList",
        component:()=>import("../../views/Address/AddressList.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/addaddress",
        name:"addaddress",
        component:()=>import("../../views/Address/AddAddress.vue"),
        meta:{
            keepAlive:true
        }
    },
]