

export default [
    {
        path:"/userInfo",
        name:"userInfo",
        component:()=>import("../../views/Basics/UserInfo.vue"),
        meta:{
            keepAlive: true
        }
    },
    {
        path:"/userPhoto",
        name:"userPhoto",
        component:()=>import("../../views/Basics/UserPhoto.vue"),
        meta:{
            keepAlive: true
        }
    },
    {
        path:"/userSetName",
        name:"userSetName",
        component:()=> import("../../views/Basics/UserSetName.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/signature",
        name:"signature",
        component:()=> import("../../views/Basics/UserSignature.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/userSetPass",
        name:"userSetPass",
        component:()=>import("../../views/Basics/SetPass.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/vTMessage",
        name:"vTMessage",
        component:()=>import("../../views/Basics/VTMessage.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/school",
        name:"school",
        component:()=>import("../../views/Basics/School.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/set",
        name:"set",
        component:()=>import("../../views/Basics/Set/Set.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/money",
        name:"money",
        component:()=>import("../../views/Basics/Money.vue"),
        meta:{
            keepAlice:true
        }
    },
    {
        path:"/bill",
        name:"bill",
        component:()=>import("../../views/Basics/Bill.vue"),
        meta:{
            keepAlice:true
        }
    },
    {
        path:"/about",
        name:"about",
        component:()=>import("../../views/Basics/About.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/informList",
        name:"informList",
        component:()=>import("../../views/Basics/InformList.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/informSuc",
        name:"informSuc",
        component:()=>import("../../views/Basics/InformSuc.vue"),
        meta:{
            keepAlive:true
        }
    }
]
