


































import { Vue, Component, Prop, Emit, Ref, Watch } from "vue-property-decorator";
import { IndexStore } from "@/store/modules/Index"
import {DelScroll, isStopScroll} from "@/util/Adaptive";

interface PullDownType<T = string, Y = number> {
  initData():void;
  handlePullDown():void;
  handleStartScroll(e: any): void;
  handleMoveScroll(e: any): void;
  handleEndScroll(e: any): void;
  handleClearTransformY(): void;
  handleScroll(e: any): void;
}

@Component({ name: "PullDownUp" })
export default class PullDownUp extends Vue implements PullDownType {

  @Ref("list")
  listHtml!: HTMLDivElement;

  @Ref("listpulldown")
  pullDown!: HTMLDivElement;

  @Ref("Footer")
  footer!: HTMLDivElement;

  @Ref("listBox")
  Cont!:HTMLDivElement;

  private transitionState = false
  private PullDownIcon = require("../../assets/icon/Basics/PullDown.png");
  private headShow = false;
  private BIli!: number;
  private scrollBall!: number;
  private UpDownBool = false;
  private BoxHeight!: number;
  private maxTranslateY = 200; // 下拉超过 200 什么都不做
  // private minTranslateY = 50; // 下拉小于 50 什么都不做 说明下拉的范围太小
  private PullDownNum = 0;
  private IsShowLoading = false;
  private PullHeadNum = 100; // 下拉的高度
  private PullDownState = false; // 默认不显示 松手加载数据
  private Chazhi = 0; // 获取不同手机的 rem 运算差值
  private Show = false
  private Loading = false
  private ScrollNum = 0

  private StartY = 0;
  private ScrollHeight = 0

  mounted() {
    let height:string|number = this.listHtml.offsetHeight
    this.BoxHeight = height;
    if ( this.getCustomScroll ){
      isStopScroll(this.listHtml)
    }

    if ( this.getValueBool ){
      this.pullValue()
    }

    let time = setTimeout(()=>{
      this.transitionState = true
      clearTimeout(time)
    },3000)
  }

  initData(){
      this.BIli = this.BoxHeight / Number(this.Cont.scrollHeight)
      this.scrollBall = this.BIli * this.BoxHeight;
  }

  handlePullDown(){
    this.Loading = true
    this.PullDown();
  }

  handleStartScroll(e: any) {
    this.StartY = e.touches[0].pageY;
    e.preventDefault()
  }

  handleMoveScroll(e: any) {
    this.initData()
    const pageY = e.touches[0].pageY;
    const diff = (pageY - this.StartY) //* this.Chazhi;
    if ( this.ScrollHeight <= 0 ){
      this.ScrollNum = 0
    }else{
      // eslint-disable-next-line
      // @ts-ignore
      this.ScrollNum = diff * window.Cha
    }
    e.preventDefault()
  }

  handleEndScroll(e:any) {
    e.preventDefault()
  }

  handleClearTransformY() {
    this.listHtml.style.transform = `translateY(0px)`;
    this.pullDown.style.marginTop = -this.PullHeadNum + "px";
    let time = setTimeout(() => {
      this.IsShowLoading = false;
      this.listHtml.style.transform = "";
      let times = setTimeout(() => {
        this.listHtml.style.transition = "";
        clearTimeout(times);
      }, 200);
      clearTimeout(time);
    }, 200);
  }

  handleScroll(e: any) {
    // 禁止滚动
    if ( this.getScrollForbid ){
      if ( this.GetScrollBool && IndexStore.GetScrollNum <= 20 ){
        this.listHtml.scrollTop = 0
      }
      e.preventDefault();
      e.stopPropagation()
      return;
    }
    // 初始化
    this.initData()
    let scrollTop = Number(this.listHtml.scrollTop)
    let scroolNum: number = ( scrollTop * this.BIli ) + this.scrollBall;
    // let scroolNum: number = scrollTop + this.scrollBall;
    this.ScrollNum = scrollTop

    if( this.GetPullDownBool ){
      this.Show = true
    }else{
      if( this.ScrollNum < 4 ){
        this.Show = false
      }else{
        this.Show = true
      }
    }

    // if ( this.getValueBool ){
    //   this.pullValue()
    // }


    if( this.GetScrollBool ){// 记录值
      IndexStore.SetScrollNum( scrollTop )
    }
    if ( (this.BoxHeight - this.GetUpDownNum) <= scroolNum) {
      if (!this.UpDownBool) {
        this.UpDownBool = true;
        this.UpDown();
      }
    } else {
      this.UpDownBool = false;
    }

    e.preventDefault();
  }

  @Prop([String, Number])
  height!: string | number;
  // 设定容器的高度
  get GetHeight(): string | number {
    if (this.height) {
      return this.height + "px";
    } else {
      // listHtml
      return "100vh";
    }
  }

  @Prop([String, Number])
  width!: string | number;
  // 设定 容器的宽度
  get GetWidth(): string | number {
    if (this.width) {
      return this.width + "px";
    } else {
      return "100vw";
    }
  }

  @Prop(Boolean)
  upDown!: boolean;
  // 是否 使用上啦加载 函数 默认 使用
  get GetUpDown(): boolean {
    if (this.upDown) {
      return false;
    } else {
      return true;
    }
  }

  // 距离底部多少执行上啦 默认 10
  @Prop(Number)
  UpDownNum!: number;

  get GetUpDownNum(): number {
    if (this.UpDownNum) {
      return this.UpDownNum;
    } else {
      return 10;
    }
  }

  // 是否使用 下拉加载 刷新 默认使用
  @Prop(Boolean)
  PullDownBool!: boolean;

  get GetPullDownBool(): boolean {
    if (this.PullDownBool) {
      this.Show = true
      return  true//false;//
    } else {
      return  false//true; //
    }
  }

  @Watch("GetPullDownBool")
  ChangePullDownBool(newVal:boolean){
    if (newVal) {
      this.Show = true
    }
  }

  @Prop(String)
  loadingText!: string;

  get GetLoadingText(): string {
    if (this.loadingText) {
      return this.loadingText;
    } else {
      return "下拉刷新";
    }
  }

  // 下拉加载完成
  @Prop(Boolean)
  pullDownloading!: boolean;

  get GetPullDownloading() {
    return this.pullDownloading;
  }

  @Watch("GetPullDownloading")
  handlePullDownloading(newold: boolean): void {
    if (newold) {
      // 清除下拉加载 样式
      // this.handleClearTransformY();
      this.Loading = false
    }
  }

  // 是否记录 滚筒条的高度
  @Prop(Boolean)
  scrollBool!:boolean;

  get GetScrollBool(){
    if( this.scrollBool ){
      return this.scrollBool
    }else{
      return false
    }
  }

  // 滚动条 滚动值的高度
  @Prop(Number)
  scrollNum!:number;
  get GetScrollNum(){
    return this.GetScrollBool && this.scrollNum || 0
  }
  @Watch("GetScrollNum",{ deep:true })
  handleGetScrollNum(newVal:number){
    if( !this.GetScrollBool )return;
    let time = setTimeout( ()=>{
      this.listHtml.scrollTop = newVal || 0
      clearTimeout( time )
    },300 )
  }

  @Prop(Boolean)
  valueBool!:boolean
  get getValueBool(){ return this.valueBool || false }

  @Emit("pullValue") // 抛出组件的 滚动高度
  pullValue(){
    let listContHeight = this.listHtml.scrollHeight,
            listHeight = this.listHtml.offsetHeight,
            scrollHeight = listContHeight - listHeight;
    return {
      scrollHeight:scrollHeight > 0 && scrollHeight || 0,
      contHeight:listContHeight,
      listHeight,
      listDiv:this.listHtml
    }
  }

  // 下拉加载函数触发
  @Emit("PullDown")
  PullDown() {
    return true;
  }
  // 上啦加载函数触发
  @Emit("UpDown")
  UpDown() {
    return true;
  }

  @Prop(String)
  background!:string

  get GetBackground():string{
    if( this.background ){
      return this.background as string
    }else{
      return "write"
    }
  }

  @Prop(Boolean)
  customScroll!:boolean
  get getCustomScroll(){
    return this.customScroll || false
  }
  @Watch("getCustomScroll")
  changeCustomScroll(newVal:boolean){
    if ( newVal ){
      isStopScroll(this.listHtml)
    }else{
      DelScroll(this.listHtml)
    }
  }

  // 是否禁止滑动
  @Prop(Boolean)
  scrollForbid!:boolean
  get getScrollForbid(){
    return this.scrollForbid || false
  }
  @Watch("getScrollForbid")
  changeScrollForbid(newVal:boolean){
    if ( !newVal ){
      // isStopScroll(this.listHtml)
    }else{
      if (this.GetScrollBool && this.GetScrollNum < 20) {
        this.listHtml.scrollTop = 0
      }
    }
  }
}
