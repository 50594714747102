












import { Vue,Component,Ref, Prop, Watch,Emit } from "vue-property-decorator"

@Component({ name:"ScrollView" })
export default class ScrollView extends Vue{
    scrollHeight = 0; // 外包盒
    scrollBoxHeight = 0; // 内容包盒
    PageY = 0; // 点击开始的pageY
    Cha = 0; // 获取是 渲染的差值
    htmlTop = 0; // 内容 top
    boxHeight = 0;// 滚动盒子的最高 高度
    Top = 0;
    Num = 0;
    time:any = ""
    AnimationBool = false;
    MoveBool = false; // 是否移动

    mounted() {
        // eslint-disable-next-line
        // @ts-ignore
       this.Cha = window.Cha
       this.GetScrollHtml.addEventListener("touchstart",this.handleStart)
       this.GetScrollHtml.addEventListener("touchmove",this.handleMove)
       this.GetScrollHtml.addEventListener("touchend",this.handleEnd)
    }

    handleStart(e:any){
       let touch = e.changedTouches[0]
       this.PageY = touch.pageY
       this.htmlTop = this.Top
       this.boxHeight = this.GetScrollBoxHtml.offsetHeight - this.GetScrollHtml.offsetHeight

       this.AnimationBool = false
       this.Num = 0
       this.time = setInterval(()=>{
       this.Num ++
       })

       //  e.preventDefault();
       //  e.stopPropagation()
   }

    handleMove(e:any){
       let touch = e.changedTouches[0]
       let PageY = ( touch.pageY - this.PageY ) //* this.Cha
       let Top = PageY + this.htmlTop
       if( this.boxHeight < 0 )return;

       if( Top >= 0 ){
           this.Top = 0
       }else if( Math.abs(Top) >= this.boxHeight ){
           this.Top = -this.boxHeight
       }else{
           this.Top = Top
       }

       if( this.GetScrollBool ){
           this.PullReftch()
       }

       this.MoveBool = true
       //  e.preventDefault();
       //  e.stopPropagation()
    }

    handleEnd(e:any){
       if( this.boxHeight < 0 )return;
       if( !this.MoveBool )return;

       if( this.Num <= 40 ){
           this.AnimationBool = true
           if( this.Top >= 0 ){
               this.Top = 0
           }else if( Math.abs(this.Top) >= this.boxHeight ){
               this.Top = -this.boxHeight
           }else{
               let Top = 0;
               if( this.Top < this.htmlTop ){
                   Top = this.Top + (this.Top * 0.2)
               }else{
                   Top = this.Top / 3
               }
               if( Top >= 0 ){
                   this.Top = 0
               }else if( Math.abs(Top) >= this.boxHeight ){
                   this.Top = -this.boxHeight
               }else{
                   this.Top = Top
               }
           }
       }else{
           this.AnimationBool = false
       }
       clearInterval( this.time )
       if( this.GetScrollBool ){
           this.PullReftch()
       }

       this.MoveBool = false
       e.preventDefault();
       e.stopPropagation()
    }

    @Ref("scroll")
    scrollHtml!:HTMLDivElement;

    get GetScrollHtml(){
        return this.scrollHtml
    }

    @Ref("scrollBox")
    scrollBoxHtml!:HTMLDivElement;

    get GetScrollBoxHtml(){
        return this.scrollBoxHtml
    }

    @Prop(Number)
    scrollTop!:number;

    get GetScrollTop(){
        return this.scrollTop
    }

    @Watch("GetScrollTop")
    handleChangeScrollTop(newVal:any){
        this.AnimationBool = true
        let Top = newVal * this.Cha;
        this.boxHeight = this.GetScrollBoxHtml.offsetHeight - this.GetScrollHtml.offsetHeight
        if( Top > this.boxHeight ){
            Top = this.boxHeight
        }
        this.Top = -Top
    }

    @Prop(Boolean)
    scrollBool!:boolean

    get GetScrollBool(){
        if( this.scrollBool ){
            return true
        }else{
            return false
        }
    }

    @Emit("PullReftch")
    PullReftch(){
        return this.Top
    }
}
