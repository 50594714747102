
export default [
    {
        path:"/deliverySuc",
        name:"deliverySuc",
        component:()=>import("../../views/Dispatching/DeliverySuc/DeliverySuc.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/bikeSuc",
        name:"bikeSuc",
        component:()=>import("../../views/Dispatching/BikeSuc/BikeSuc.vue"),
        meta:{
            keepAlive: true
        }
    },
]
