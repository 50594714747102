
export default [
    {
        path:"/businessSuc",
        name:"businessSuc",
        component:()=>import("../../views/ShopAdmin/BusinessSuc/BusinessSuc.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/siteOrder",
        name:"siteOrder",
        component:()=>import("../../views/ShopAdmin/SiteOrder/SiteOrder.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/distance",
        name:"distance",
        component:()=>import("../../views/ShopAdmin/Distance/Distance.vue"),
        meta:{
            keepAlive: true
        }
    },
]
