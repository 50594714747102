import { Basics } from "../Config/url"
import axios from "axios"
import {Dialog, Notify, Toast} from "vant"

let Axios = axios.create()
Axios.defaults.baseURL = Basics
Axios.defaults.headers.post["type"] = "wxpublic"

Axios.defaults.timeout = (100*1000)

Axios.interceptors.request.use(config=>{
    return config
},error=>{
    Promise.reject(error)
})

Axios.interceptors.response.use(response=>{
    let code = response.data.message.code
    if ( code === "200" ){
        return response.data
    }else{
        Toast.clear()
        if ( code === "401" ){
            let time = setTimeout(()=>{
                Dialog.confirm({
                    title: '温馨提示',
                    message: '登录过期请重新登录',
                    beforeClose(action, done) {
                        if (action === 'confirm') {
                            window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bcf48887c6bacf2&redirect_uri=https://mobile.31xiaoyuan.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect"
                            done();
                        } else {
                            done();
                        }
                    },
                })
                clearTimeout( time )
            },800)

        }else{
            try{
                Notify({
                    message: response.data.message.msg,
                    color: 'white',
                    background: "rgba(0,0,0,.6)",
                });
            }catch (e) {
                console.info("提示失败")
            }
            return null
        }
    }
},error=>{
    if ( error.message.includes('timeout') ){
        Notify({
            message: "请求超时",
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }
    return Promise.reject(error)
})



interface UpPhotoHeadType<T=string>{
    userId:T | number;
    token:T
}
// UpPhotoType
// 单文件添加
export default function UpPhoto( headers:UpPhotoHeadType,body:any ):Promise<any>{
    return (
        Axios({
            url:"/upload/uploadFile",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 多文件 添加
export function UpPhotos( headers:UpPhotoHeadType,body:any ):Promise<any>{
    return (
        Axios({
            url:"/upload/uploadFiles",
            method:"POST",
            headers,
            data:body
        })
    )
}

