
import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "../index"
import Storage from "@/util/Storage"
import { ShopStateType } from "@/Type/StoreType"
import { IndexType } from "@/store/indexType"

@Module({
    name:"Index",
    store,
    dynamic: true,
})
export default class Index extends VuexModule implements IndexType{
    ScrollNum = 0;
    LoadingBool = false;
    ShopState: ShopStateType<number|string> = {
        state:1,
        title:"零食铺子",
        type:2
    }

    // loading 加载状态
    get GetLoading():boolean{
        return this.LoadingBool
    }

    @Mutation
    SetLoading(data:boolean):void{
        this.LoadingBool = data
    }
    // 零食铺子的状态
    get GetShopState(){
        return this.ShopState || JSON.parse( Storage.GetData_ && Storage.GetData_("ShopState") )
    }
    @Mutation
    SetShopState( data:ShopStateType<string|number> ){
        this.ShopState = data
        Storage.SetData_ && Storage.SetData_("ShopState",JSON.stringify( data ))
    }
    // 列表滚动条 距离记录
    get GetScrollNum(){
        return this.ScrollNum
    }
    @Mutation
    SetScrollNum(data: number): void {
        this.ScrollNum = data
    }

    IncidentBool = true;
    get getIncidentBool(){ return this.IncidentBool }
    @Mutation
    SetIncidentBool(bool: boolean): void {
        this.IncidentBool = bool
    }

    // 滚动 禁止
    ScrollStopBool = false;
    get getScrollStopBool(){ return this.ScrollStopBool }
    @Mutation
    SetScrollStopBool(bool: boolean): void {
        this.ScrollStopBool = bool
    }
}

export const IndexStore = getModule( Index )
// HomeLoading:boolean;
// SetHomeLoading(data:boolean):void;
// HomeLoading = false;
// get GetHomeLoading(){
//     return this.HomeLoading
// }

// @Mutation
// SetHomeLoading( data:boolean ){
//     this.HomeLoading = data
// }
