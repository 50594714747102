



























    import {Vue, Component, Watch} from "vue-property-decorator"
 import {MessageStore} from "@/views/Message/Store";

 interface ListItem{
     active:any;
     inactive:any;
     title:string;
     num:number;
 }
interface IndexType{
    active:number | undefined;
    List:Array<ListItem>;

    initData():void;
    onChange(e:number|undefined|string|null):void;
}

 @Component({ name:"Index" })
 export default class Index extends Vue implements IndexType{
     active = 0;
     List = [
        {
            active:require("../assets/icon/Basics/ActiveHome.png"),
            inactive: require("../assets/icon/Basics/home.png"),
            title:"首页",
            num:0,
            to:"/"
        },
        {
            active:require("../assets/icon/Basics/ActiveList.png"),
            inactive: require("../assets/icon/Basics/List.png"),
            title:"列表",
            num:0,
            to:"/list"
        },
        {
            active:require("../assets/icon/Basics/ActiveUser.png"),
            inactive: require("../assets/icon/Basics/user.png"),
            title:"我的",
            num:0,
            to:"/user"
        }
     ]
    mounted() {

        let href = window.location.hash
        let reg = /user/g,
            reg1 = /list/g,
            reg2 = /friendsCircle/g,
            reg3 = /messageList/g;
        if ( "attachEvent" in window ){
            if( reg.test( href ) ){
                this.active = 2
            }else if( reg1.test(href) ){
                this.active = 1
            }else {
                this.active = 0
            }
        }else{
            if( reg.test( href ) ){
                this.active = 4
            }else if( reg3.test(href) ){
                this.active = 3
            }else if( reg2.test(href) ){
                this.active = 2
            }else if( reg1.test(href) ){
                this.active = 1
            }else {
                this.active = 0
            }
        }
        this.initData()
    }

     initData(){
         let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
         if ( bool != null && bool[0] == "micromessenger" ){
             let Index = this.List.findIndex(a=>a.title == '朋友圈')
             if ( Index >= 0 ) this.List.splice(Index,1)
             let MessageIndex = this.List.findIndex(a=>a.title == '消息')
             if ( MessageIndex >= 0 ) this.List.splice(Index,1)
         }else{
             let Index = this.List.findIndex(a=>a.title == '朋友圈')
             if ( Index < 0 ) this.List.splice(2,0,{
                 active:require("../assets/icon/Basics/ActiveFriend.png"),
                 inactive: require("../assets/icon/Basics/Friend.png"),
                 title:"朋友圈",
                 num:0,
                 to:"/friendsCircle?homeFriend=true"
             })
             let MessageIndex = this.List.findIndex(a=>a.title == '消息')
             if ( MessageIndex < 0 ) this.List.splice(3,0,{
                 active:require("../assets/icon/Basics/ActiveCart.png"),
                 inactive: require("../assets/icon/Basics/Cart.png"),
                 title:"消息",
                 num:MessageStore.getNewestMessageNavNum || 0,
                 to:"/messageList?footerNav=true"
             })
         }
     }

     onChange(e:number|string){
        e = Number( e )
        if( e == 2 ){
            // 用户列表
            this.$router.push({
                name:"user"
            })
        }else if( e == 1 ){
            // 列表
            this.$router.push({
                name:"list"
            })
        }else{
            // 首页
            this.$router.push({
                name:"home"
            })
        }
     }

     get getNavNum(){
         return MessageStore.getNewestMessageNavNum
     }
     @Watch("getNavNum")
     changeNavNum(newVal:number){
         let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
         if ( !(bool != null && bool[0] == "micromessenger") ){
             let MessageIndex = this.List.findIndex(a=>a.title == '消息')
             if ( MessageIndex < 0 ) this.List.splice(3,0,{
                 active:require("../assets/icon/Basics/ActiveCart.png"),
                 inactive: require("../assets/icon/Basics/Cart.png"),
                 title:"消息",
                 num:newVal || 0,
                 to:"/messageList?footerNav=true"
             });
             else
                 this.List[MessageIndex]["num"] = newVal;
         }
     }
 }
