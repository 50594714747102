


export default [
    {
        path:"/authentication",
        name:"authentication",
        component:()=>import("../../views/Authent/AuthentList.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/autherIdentity",
        name:"autherIdentity",
        component:()=>import("../../views/Authent/AuthentSuc.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/auther",
        name:"auther",
        component:()=>import("../../views/Authent/Authent.vue"),
        meta:{
            keepAlive:true
        }
    }
]