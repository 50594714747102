import { Second } from "./url"
import Axios from "axios"
import { Notify,Dialog } from "vant"
import router from "@/router";
import {Toast} from "vant/types/toast";

const axios = Axios.create()
axios.defaults.baseURL = Second
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.post["type"] = "wxpublic"


axios.defaults.timeout = (10*1000)

// 请求前
axios.interceptors.request.use( config=>{
    return config
},error=>{
    Promise.reject(error)
} )
// 请求后
axios.interceptors.response.use( response=>{
    let code = response.data.message.code
    if ( code === "200" ){
        return response.data
    }else{
        // Toast.clear()
        if ( code === "401" ){
            let time = setTimeout(()=>{
                Dialog.confirm({
                    title: '温馨提示',
                    message: '登录过期请重新登录',
                    beforeClose(action, done) {
                        if (action === 'confirm') {
                            let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
                            if ( bool != null && bool[0] == "micromessenger" ){
                                window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bcf48887c6bacf2&redirect_uri=https://mobile.31xiaoyuan.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect"
                            }else{
                                router.replace({
                                    name:"register"
                                })
                            }
                            done();
                        } else {
                            done();
                        }
                    },
                })
                clearTimeout( time )
            },800)

        }else{
            try{
                Notify({
                    message: response.data.message.msg,
                    color: 'white',
                    background: "rgba(0,0,0,.6)",
                });
            }catch (e) {
                console.info("提示失败")
            }
            return null
        }
    }
},error=>{
    if ( error.message.includes('timeout') ){
        Notify({
            message: "请求超时",
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }
    return Promise.reject(error)
} )


export default axios;
