import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import Storage from "../util/Storage"
import Index from "../views/Index.vue";
import Basics from "./paths/Basics"
import Authent from "./paths/Authent"
import Coupon from "./paths/Coupon"
import Address from "./paths/Address"
import Express from "./paths/Express"
import Shop from "./paths/Shop"
import Help from "./paths/Help"
import Hand from "./paths/Hand"
import Class from "./paths/Class"
import SchoolSuc from "@/router/paths/SchoolSuc"
import Friend from "@/router/paths/Friend";
import Login from "@/router/paths/Login"
import Message from "@/router/paths/Message"
import Money from "@/router/paths/Money";
import Dispatching from "@/router/paths/Dispatching";
import ShopAdmin from "@/router/paths/ShopAdmin";
import Group from "@/router/paths/Group";
import Other from "@/router/paths/Other";
import {IndexStore} from "@/store/modules/Index";
import {CouponStore} from "@/store/modules/Basics/Coupon";
import {UserStore} from "@/store/modules/User";
import {Toast} from "vant";
import {getUserInfoData} from "@/views/Friend/Server";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "index",
        component: Index,
        children: [
            {
                path: "/",
                name: "home",
                component: () => import("../views/Home.vue"),
            },
            {
                path: "/user",
                name: "user",
                component: () => import("../views/User.vue")
            },
            {
                path: "/list",
                name: "list",
                component: () => import("../views/List.vue")
            },
            {
                path: "/friendsCircle",
                name: "friendsCircle",
                component: () => import("../views/Friend/FriendCircle/FriendCircle.vue")
            },
            {
                path: "/messageList",
                name: "messageList",
                component: () => import("../views/Message/MessageList/MessageList.vue")
            }
        ],
    },
    ...Basics,
    ...Authent,
    ...Coupon,
    ...Address,
    ...Express,
    ...Shop,
    ...Help,
    ...Hand,
    ...Class,
    ...SchoolSuc,
    ...Friend,
    ...Login,
    ...Message,
    ...Money,
    ...Dispatching,
    ...ShopAdmin,
    ...Group,
    ...Other,
];

if (process.env.NODE_ENV == "development") {
    console.log("页面数量 " + routes.length);
}

const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, sacedPosition) {
        let time: any = setTimeout(() => {
            if (sacedPosition) {
                return sacedPosition
            } else {
                return {x: 0, y: 0}
            }
            clearTimeout(time)
        })
    },

});

/** 链接参数 外卖请求状态 */
function getShopState(state: string, title?: string) {
    switch (Number(state)) {
        case 0:
            IndexStore.SetShopState({
                state: 0,
                title: title || '校园外卖',
                type: 0
            })
            break
        case 1:
            IndexStore.SetShopState({
                state: 1,
                title: title || '校园百货',
                type: 1
            })
            break
        case 2:
            IndexStore.SetShopState({
                state: 2,
                title: title || '零食铺子',
                type: 2
            })
            break
        case 3:
            IndexStore.SetShopState({
                state: 3,
                title: title || '新鲜水果',
                type: 3
            })
            break
    }
    CouponStore.SetSelCoupon({})
    UserStore.SetSelAddress({})
}

/** barShow 是否显示 */
function IsBarShow(to: any, from: any, next: any) {
    let url = window.location.origin
    if (/\?/g.test(to.fullPath)) {
        url += ("/#" + to.fullPath + "&barShow=true")
    } else {
        url += ("/#" + to.fullPath + "?barShow=true")
    }
    if (!to.query["barShow"]) next();
    if (to.name && from.name && to.name != from.name && from.query["barShow"] && !to.query["barShow"]) {
        to.query["barShow"] = "true"
        next()
        setTimeout(() => {
            window.history.replaceState('', '', url)
        }, 800)
    } else {
        next()
    }
}

// shopType:外卖类型 shopTitle:外卖标题  barShow:标题是否显示  userId token
router.beforeEach((to, from, next) => {
    console.log(to, from)
    let {query} = to
    if (JSON.stringify(query) != "{}") {
        /** barShow 标题是否先显示 */
        let {userId, token} = query
        try {
            if (userId && token) {
                /** userId && token */
                Storage.SetAllData && Storage.SetAllData("userId", userId as string);
                Storage.SetAllData && Storage.SetAllData("token", token as string);
                // IndexStore.SetLoading(true)
                /** shopType 外卖请求状态 */
                if (query["shopType"]) {
                    getShopState(query["shopType"] as string, query["shopTitle"] as string && query["shopTitle"] as string || '')
                }
                // 通讯录
                if (query["phoneHook"]) {
                    try {
                        try {
                            let PhoneHook = JSON.parse(query["phoneHook"] as string)
                            Storage.SetAllData?.("phoneHook", JSON.stringify(PhoneHook) as string)
                        } catch (e) {
                            let PhoneHook = eval(query["phoneHook"] as string)
                            Storage.SetAllData?.("phoneHook", JSON.stringify(PhoneHook) as string)
                        }
                    } catch (e) {
                        console.info("转换失败")
                    }
                }
                /** 个人信息 */
                let data = Storage.GetData_ && JSON.parse(Storage.GetData_("UserData"))
                if (!data) {
                    getUserInfoData().then(res => {
                        IsBarShow(to, from, next)
                    })
                } else {
                    IsBarShow(to, from, next)
                }
            } else {
                IsBarShow(to, from, next)
            }
        } catch (e) {
            IsBarShow(to, from, next)
        }
    } else {
        IsBarShow(to, from, next)
    }
    Toast.clear()
})

export default router;
