
export default [
    {
        path:"/allExpress",
        name:"allExpress",
        component:()=>import("../../views/Express/AllExpress.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/issueExpress",
        name:"issueExpress",
        component:()=>import("../../views/Express/IssueExpress.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/myExpress",
        name:"myExpress",
        component:()=>import("@/views/Express/MyExpress.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/expressSuc",
        name:"expressSuc",
        component:()=>import("@/views/Express/ExpressSuc.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/feedbackExpOrder",
        name:"feedbackExpOrder",
        component:()=>import("@/views/Express/FeedbackOrder/FeedbackOrder.vue"),
        meta:{
            keepAlive:true
        }
    }
]
