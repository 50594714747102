










import { Component,Vue,Prop } from "vue-property-decorator"

interface LoadingType {
    lodingIcon : string | undefined ;
}
// @Provide()
@Component({
    name:"Loading"
})
export default class Loading extends Vue implements LoadingType{
    lodingIcon = require("../../assets/loading/Loading.gif")

    @Prop( Boolean )
    TextBool : boolean | undefined;

    get loadingTxtBool ():boolean{
        if( ( typeof this.TextBool ) === "boolean" ){
            return this.TextBool as boolean
        }else{
            return false
        }
    }

    @Prop(String)
    Text:string | undefined;

    get loadingText():string{
        if( ( typeof this.Text ) === "string" ){
            return this.Text as string
        }else{
            return "网络异常请重新登录"
        }
    }
}

