
















 import { Vue,Component,Prop,Emit } from "vue-property-decorator"

interface SearchType{
    SearchIcon:any;
    handleToPath():void
}

 @Component({ name:"Search" })
 export default class Search extends Vue implements SearchType{
    SearchIcon = require("../../assets/icon/Basics/search.png");

    @Prop(String)
    serchVal?:string;

    get GetSerchVal(){
        if( typeof(this.serchVal) === "string" ){
            return this.serchVal
        }else{
            return "搜索"
        }
    }

    @Prop( [String,Number] )
    state?: number | string ;

    get GetState(){
        switch ( Number( this.state ) ){
            case 1:
                return "inputText StopState";
            case 2:
                return "inputText SearchState";
            case 3:
                return "inputText StudyState";
            case 4:
                return "";
            default:
                return "inputText";
        }
    }
    get GetState_(){
        switch ( Number( this.state ) ){
            case 1:
                return "searchBox ActiveSearchBox";
            case 2:
                return "searchBox SearchNBox";
            case 3:
                return "searchBox";
            case 4:
                return "";
            default:
                return "searchBox";
        }
    }

    @Prop( Boolean )
    clickBool!:boolean;
    get getClickBool(){
        return this.clickBool || false
    }

    handleToPath(){
        if( this.getClickBool ){
            // 返回一个 Emit
            this.ToPath()
        }else{ // 默认跳转搜索页面
            this.$router.push({
                path:""
            })
        }
    }

    @Emit("ToPath")
    ToPath(){
        return true
    }

 }
