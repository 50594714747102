











import { Toast,Notify } from "vant"
import { parse } from "qs"
import { WxToken,WxUserInfo } from "./Api/WX/index"
import { GetOpenId,GetUserData } from "./Api/Basics/index"
import { IndexStore } from "./store/modules/Index"
import Stroage from "./util/Storage"
import {Vue, Component, Watch, Ref,} from "vue-property-decorator";
import {initLocation} from "@/location/location";
import {isStopScroll, overscroll,DelScroll} from "@/util/Adaptive";
import AppType,{ WXUserType,UserType,OpenType } from "./App"
import { networkState, socketLink} from './App_H5'

@Component
export default class App extends Vue implements AppType {
  href: string | undefined;
  code: Array<string> | string | undefined;
  userId:string |number | undefined;
  token:string | undefined;
  openId :string | undefined;
  loadingBool = true;
  ErrorBool = false;
  transitionName = 'slide-left'

  @Ref("App")
  appDiv!:HTMLDivElement

  @Watch("$route")
  changeRoute(to:any,from:any){
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
  }

  mounted() {
    // Stroage.SetAllData && Stroage.SetAllData("OpenId","oKXnO0T-BB8gPgCGVkmvFii7Kb0I") //4CB481A1F9D9A5A0262C2733BB882037
    this.init()
    initLocation()
    this.initScroll()
  }

  get getIncident(){return IndexStore.getIncidentBool}
  @Watch("getIncident")
  changeIncident(newVal:boolean){
    this.initScroll()
  }
  initScroll(){
    if (this.getIncident) {
      DelScroll(this.appDiv)
    }else {
      isStopScroll(this.appDiv)
    }
  }

  init(): void {
    // https://wx.gzh.31xiaoyuan.com/?code=071mZY0005pSJK1X3S200xke3r2mZY0n&state=1#/ oKXnO0T-BB8gPgCGVkmvFii7Kb0I 2 6F700999DF6B183FCC55C0C87B922A42

    let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
    if ( bool != null && bool[0] == "micromessenger" ){
      let data = parse(window.location.search.replace(/\?/,''));
      if ( data["code"] && data["code"].length && (typeof data["code"]) === "string" ){
        this.initSkip()
      }else{
        this.isLoginOpenId()
      }
    } else{
      this.isLoginOpenId()
    }
  }
  // 获取本地的 openId userId token 进行登录
  isLoginOpenId(){
    let openId:string | undefined | null = Stroage.GetData?.("OpenId") || ''
    let userId:string | undefined | null = Stroage.GetData?.("userId") || ''
    let token:string | undefined | null = Stroage.GetData?.("token") || ''
    if( Boolean( openId ) ){
      this.openId = openId as string
      if ( userId && token && userId.length && token.length ){
        this.userId = (userId as string)
        this.token = (token as string)
        this.getUserData( userId ,token )
      }else{
        this.getUserInfo( openId as string )
      }
    }else{
      if ( userId && token && userId.length && token.length ){
        this.userId = (userId as string)
        this.token = (token as string)
        this.getUserData( userId ,token )
      }else{
        this.initSkip()
      }
    }
  }
  // 区分 公众号 还是其他设备登录
  async initSkip(){
    this.href = window.location.search.replace(/(#|\/|\?)/g,'')//window.location.hash.split("?")[1]//replace(/(#|\/|\?)/g,"");
    let data = {
      ...parse(this.href),
      ...parse(window.location.hash.split("?")[1])
    };
    let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
    if ( bool != null && bool[0] == "micromessenger" ){
      if ( data["code"] && (typeof data["code"]) === "string" ){
        this.getOpenId( data["code"] as string )
      }else{
        this.isLoginKey(data)
      }
    } else{
      this.isLoginKey(data)
      // this.ErrorBool = true
    }
  }
  // 判断链接中 是否传递 userId 或者 token
  isLoginKey(data:any = {}){
    if ( data["userId"] || data["token"] ){
      this.userId = (data["userId"] as string)
      this.token = (data["token"] as string)
      this.getUserData( data["userId"] as string ,data["token"] as string )
    }else{
      let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
      if ( bool != null && bool[0] == "micromessenger" ){
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bcf48887c6bacf2&redirect_uri=https://mobile.31xiaoyuan.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect"
      }else{
        this.loadingBool = false
        this.$router.replace({
          path:"/register"
        })
      }
    }
  }

  getOpenId( code:string ){
    WxToken( { data:code } ).then((res: any)=>{
      if( res.message.code === "200" ){
        if( Boolean( res.data.openid ) && Boolean( res.data.access_token ) ){
          this.getWxUserInfo( {
            accessToken:res.data.access_token,
            openid:res.data.openid
          } )
        }else{
          this.ErrorBool = true
          this.handleError("登录异常请重新登录")
        }
      }else{
        this.ErrorBool = true
        this.handleError("登录异常请重新登录")
      }
    })
  }
  getWxUserInfo( openData:OpenType ){
    WxUserInfo(openData).then((res:any)=>{
        if( res.message.code === "200" ){
          let WXUserData :WXUserType = JSON.parse( res.data )
          if ( (typeof WXUserData.openid ) === "string" ){
            this.getUserInfo( WXUserData.openid )
            Stroage.SetAllData?.("OpenId",WXUserData.openid)
            this.openId = WXUserData.openid
          }
          Stroage.SetData_ && Stroage.SetData_("WXUserInfo",res.data)
        }else{
          this.ErrorBool = true
          this.handleError("登录异常请重新登录")
        }
    })
  }
  getUserInfo( openId:string ){
    GetOpenId( openId ).then( (res:any)=>{
      if( res.message.code === "200" ){
        if( res.data.userId && res.data.token ) {
          this.getUserData( res.data.userId ,res.data.token )
        }
      }else{
        let reg = /(未注册|手机号未注册)/gi
        this.loadingBool = false
        // 注册登录
        if (reg.test(res.message.msg) || res.message.code == "412"){
          this.$router.replace({
            path:"/userSetPass",
          })
        }else{
          this.ErrorBool = true
          this.handleError("登录异常请重新登录")
        }
      }
    } )
  }

  getUserData( userId:string |number,token:string ){
    GetUserData({
      userId,token
    }).then( (res:any)=>{
      this.ErrorBool = false
      this.loadingBool = false
      if( res.message.code === "200" ){
        if( userId ){
          this.userId = userId
          Stroage.SetAllData?.("userId",userId as string)
        }
        if( token ){
          this.token = token
          Stroage.SetAllData?.("token",token)
        }
        if ( res.data["openId"] ) Stroage.SetAllData?.("OpenId",res.data["openId"]);
        Stroage.SetData_?.("UserData",JSON.stringify( res.data ))
        this.handleIsData( res.data )
      }else{
        this.ErrorBool = true
        this.handleError("登录异常请重新登录")
      }
    } )
  }

  handleIsData( data:UserType ){
    // let WxData: WXUserType = JSON.parse( Stroage.GetData_ && Stroage.GetData_("WXUserInfo")  || "{}" )
    // let UpData : any = {}
    // console.log( data )
    //  // 判断 数据
    // if( !( data.name || data.nickName ) ){
    //   UpData.name = WxData.nickname
    // }
    // if( !data.openId ){
    //   UpData.openId = this.openId
    // }
    // if( !data.headImg ){
    //   UpData.headImg = WxData.headimgurl
    // }
    // if( !data.sex ){
    //   UpData.sex = WxData.sex
    // }
    // let Arr : Array<string | number> = Object.keys( UpData )
    // if( Arr.length ){
    if( !( ( data.name || data.nickName ) && data.phone && data.headImg && data.school && data.schoolName ) ){
      Toast({
        message:"跳转补全信息",
        icon:"none",
        className:"ToastClassName"
      })
      let time = setTimeout(()=>{
        this.$router.replace({
          name:"vTMessage"
        })
        clearTimeout( time )
      },1000)
    }else{
      if( !data.phone ){ // 重新注册绑定
        this.$router.replace({
          path:"/userSetPass",
        })
        return;
      }
      if ( !(data.schoolName && data.school ) ){ // 跳转到 绑定学校
        this.$router.replace({
          path:"/school",
        })
        return;
      }
      IndexStore.SetLoading( true )
    }
  }

  handleError( data:string ){
    let str = ""
    if ( data.length ){
        str = data
    }else{
        str = "网络繁忙..."
    }
    Notify({
        message: str,
        color: 'white',
        background: "rgba(0,0,0,.6)",
    });
  }

  get getLogin(){
    return IndexStore.GetLoading
  }
  @Watch("getLogin")
  change(newVal:any){
    console.log("登录状态",newVal)
    if ( newVal ) {
      // 监听 App 返回
      // backApp()
      // 网络状态
      networkState()
      // 链接 socket
      socketLink()
    }
  }
}
