















import {Vue, Component, Prop, Watch, Emit} from "vue-property-decorator";

@Component({name: "InputVal"})
export default class InputVal extends Vue {
    content = ""

    handleClick(){
        if (this.getDisabled) this.pullClick(this.getOther);
    }

    @Prop(String)
    title!:string
    get getTitle(){
        return this.title || '默认标题'
    }

    @Prop(String)
    place!:string
    get getPlace(){
        return this.place || '请输入内容'
    }

    @Prop(Object)
    other!:any
    get getOther(){
        return this.other || {}
    }

    @Prop(Boolean)
    disabled!:boolean
    get getDisabled(){
        return this.disabled || false
    }

    @Prop(String)
    value!:string
    get getValue(){
        return this.value
    }
    @Watch("getValue")
    changeValue(newVal){
        this.content = newVal;
    }

    @Emit("pullVal")
    @Watch("content")
    pullVal(newVal){
        return {
            data:newVal,
            other:this.getOther
        }
    }

    @Emit("pullClick")
    pullClick(data:any){
        return data
    }
}
