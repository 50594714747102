
















  import {Vue, Component, Prop, Emit, Ref, Watch} from "vue-property-decorator";
import { back } from "@/util/zoom";
// enum TypeSuc {
//   "返回图标后的文字" = "text",
//   "展示的标题" = "title",
//   "返回图标文字" = "textShow",
//   "返回的图标是否显示" = "backIcon",
//   "是否执行父级函数" = "EmitBool",
// }

interface HeadTopType {
  routeList:{ title:string,name:string }[]
  text?: string | undefined;
  title?: string | undefined;
  textShow?: boolean | undefined;
  backIcon?: boolean | undefined;
  iconSrc?: string | undefined;
  EmitBool?: boolean | undefined;

  initData(title?:string):void;
}

@Component({ name: "HeadTop" })
export default class HeadTop extends Vue implements HeadTopType {
  routeList = [
    { name:"home",title:"三易校园" },
    { name:"user",title:"我的" },
    { name:"myHelp",title:"我的互助" },
  ]

  mounted() {
    // eslint-disable-next-line
    // @ts-ignore
    window.Cha = this.GetHeadHtml.offsetHeight / 100
    this.initData()
  }

  initData(title?:string){
    let TitleDom:any = document.querySelector("title")
    TitleDom.innerText = title || this.Title || '三易校园'
  }

  @Watch("$route")
  changeRouter(to:any,from:any){
    this.routeList.forEach((item,index)=>{
      if ( item.name == to.name ){
        this.initData(item.title)
      }
    })
  }

  @Ref("headTop")
  headHtml!:HTMLDivElement;

  get GetHeadHtml(){
    return this.headHtml
  }

  @Prop(String)
  title!: string | undefined;
  @Prop(String)
  text: string | undefined ;
  @Prop(Boolean)
  textShow: boolean | undefined ;
  @Prop(Boolean)
  backIcon: boolean | undefined ;
  @Prop(String)
  iconSrc: string | undefined ;
  @Prop(Boolean)
  EmitBool: boolean | undefined //= false;
  get Title(): string | undefined {
      if( Boolean( this.title ) ){
        return this.title;
      }else{
        return "";
      }
  }
  get Text(): string | undefined {
      if( Boolean( this.text ) ) {
        return this.text;
      }else{
        return "返回"
      }
  }
  get TextShow(): boolean | undefined {
      if( Boolean( this.textShow ) ){
        return this.textShow;
      }else{
        return false
      }
  }
  get BackIcon(): boolean | undefined {
      if( Boolean( this.backIcon ) ){
        return false;
      }else{
         return true;
      }
  }
  get IconSrc(): string | undefined {
      if( this.iconSrc ){
        return this.iconSrc;
      }else{
        return require("../../assets/icon/Basics/GoBack.png");
      }
  }

  handleGoBack(e: any):void {
    if (this.EmitBool) {
      this.handleBack();
      return;
    }
    this.$router.go(-1);
    try{
      back()
    }catch (e) {
      console.info("返回失败")
    }
  }

  @Emit("back")
  handleBack(data: any = {}) {
    return data;
  }

  @Watch("title")
  changeTitle(newVal:string){
    if ( newVal.length ){
      this.initData()
    }
  }
}
